import {
	Fragments,
	getAnalytics,
	useRouter,
	initializeApollo,
	Queries,
	SegmentAnalytics,
	booleanFilter
} from 'common';
import {
	transformContentDocumentsToProductList,
	transformContentDocumentToProduct
} from './segmentTransformers';
import { ContentDocumentFields } from '../content';
import type { CourseTableItem } from '../../containers/MediathekHome/CoursesSection/components/CoursesTable';

// View Item List
// https://segment.com/docs/connections/destinations/catalog/actions-google-analytics-4/#view-item-list
export const contentListViewed = async (
	contentDocuments: Array<ContentDocumentFields | Fragments.ContentDocumentSearchFieldsFragment>,
	listId: string | undefined,
	locale: Locale
) => {
	if (contentDocuments.length > 0) {
		const analytics = await getAnalytics();

		const contentsForAnalytics = (
			await Promise.all(
				contentDocuments.map((contentDocument) =>
					getContentForAnalyticsById(contentDocument.contentId)
				)
			)
		).filter(booleanFilter);

		analytics?.web.productListViewed(
			transformContentDocumentsToProductList(contentsForAnalytics, listId, locale)
		);
	}
};

// Product Clicked
export const contentClicked = async (
	contentId: string,
	params: {
		locale: Locale;
		index: number | undefined;
	}
) => {
	const { content, analytics } = await getContentAndAnalytics(contentId);
	analytics.web.productClicked(transformContentDocumentToProduct(content, params));
};

// Product Clicked
export const courseClicked = async (
	course: CourseTableItem,
	params: {
		locale: Locale;
		index: number | undefined;
	}
) => {
	const { content, analytics } = await getContentAndAnalytics(course.segmentFields.contentId);
	analytics.web.productClicked(transformContentDocumentToProduct(content, params));
};

// Product Viewed
// https://segment.com/docs/connections/destinations/catalog/actions-google-analytics-4/#select-item
export const contentViewed = async (
	contentDocument:
		| ContentDocumentFields
		| Fragments.ContentDocumentSearchFieldsFragment
		| Fragments.ContentWithProgramSchedulesFieldsFragment,
	params: {
		locale: Locale;
		index: number | undefined;
	}
) => {
	const { content, analytics } = await getContentAndAnalytics(contentDocument.contentId);
	analytics?.web.productViewed(transformContentDocumentToProduct(content, params));
};

export const videoThumbnailViewed = async (
	contentId: string,
	params: {
		locale: Locale;
		index: number | undefined;
	}
) => {
	const { content, analytics } = await getContentAndAnalytics(contentId);
	analytics.web.videoThumbnailViewed(transformContentDocumentToProduct(content, params));
};

export const documentThumbnailViewed = async (
	contentId: string,
	params: {
		locale: Locale;
		index: number | undefined;
	}
) => {
	const { content, analytics } = await getContentAndAnalytics(contentId);
	analytics.web.documentThumbnailViewed(transformContentDocumentToProduct(content, params));
};

const productPathRegex = /^\/product\/(\w+)$/;
const mediathekPathRegex = /^\/mediathek(?:\/(\w+))?(?:\/(\w+))?(?:\?(\w+=\w+))?$/;

export const useGenerateListIdFromRoute = (): string | undefined => {
	const router = useRouter();
	const currentPath = router.pathname;

	switch (true) {
		case currentPath === '/':
			return 'homepage';
		case productPathRegex.test(currentPath):
			return getProductListId(currentPath);
		case mediathekPathRegex.test(currentPath):
			return getMediathekListId(currentPath);
		default:
			return undefined;
	}
};

const getProductListId = (currentPath: string): string | undefined => {
	const match = currentPath.match(productPathRegex);
	return match ? ['product', match[1]].filter(Boolean).join('-') : undefined;
};

const getMediathekListId = (currentPath: string): string | undefined => {
	const match = currentPath.match(mediathekPathRegex);
	return match
		? ['mediathek', match[1], match[2]].filter(Boolean).join('-').replace('topics', 'specialty')
		: undefined;
};

const getContentForAnalyticsById = async (
	contentId: string
): Promise<Queries.GetContentForAnalyticsByIdQuery['content'] | undefined> => {
	if (contentId.length === 0) {
		return;
	}

	const graphqlClient = initializeApollo();
	const { data } = await graphqlClient.query<
		Queries.GetContentForAnalyticsByIdQuery,
		Queries.GetContentForAnalyticsByIdQueryVariables
	>({
		query: Queries.GetContentForAnalyticsById,
		variables: {
			contentId
		}
	});
	const content = data?.content;
	return content;
};

const getContentAndAnalytics = async (
	contentId: string
): Promise<{
	content: Queries.GetContentForAnalyticsByIdQuery['content'];
	analytics: SegmentAnalytics;
}> => {
	const [content, analytics] = await Promise.all([
		getContentForAnalyticsById(contentId),
		getAnalytics()
	]);
	if (!content || !analytics) {
		return Promise.reject(!content ? 'No content' : !analytics ? 'No analytics' : '');
	}
	return { content, analytics };
};
